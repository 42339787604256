import {
    LIST_PRODUCT_ACTION,
    CREATE_PRODUCT_ACTION,
    UPDATE_PRODUCT_ACTION,
    DELETE_PRODUCT_ACTION,
} from "../actions/ProductTypes";

const initialState = {
    list: [],
    permissions: [],
};

export function ProductReducer(state = initialState, action) {
    if (action.type === LIST_PRODUCT_ACTION) {
        return {
            ...state,
            list: action.payload.list,
        };
    }
    if (action.type === CREATE_PRODUCT_ACTION) {
        const list = [...state.list];
        list.push(action.payload);
        return {
            ...state,
            list,
        };
    }


    if (action.type === UPDATE_PRODUCT_ACTION) {
        const list = [...state.list];
        const productIndex = list.findIndex((product) => product.id === action.payload.id);
        list[productIndex] = { ...list[productIndex], ...action.payload };
        return {
            ...state,
            list,
        };
    }

    if (action.type === DELETE_PRODUCT_ACTION) {
        const list = [...state.list];
        const productIndex = list.findIndex((product) => product.id === action.payload);
        list.splice(productIndex, 1);
        return {
            ...state,
            list,
        };
    }

    return state;
}
