export const URL =
  window.location.hostname.includes("test") ||
  window.location.hostname === "localhost"
    ? "https://terranova-api-test.herokuapp.com/"
    : "https://terra-nova.herokuapp.com/";

// export const URL = "https://terra-nova.herokuapp.com/";
// export const URL = "http://localhost:3001/";

export const BASE_URL = window.location.hostname.includes("test")
  ? "https://test-projects.terra-nova.io"
  : window.location.hostname === "localhost"
  ? "http://localhost:3000"
  : "https://projects.terra-nova.io";
export const STRIPE_PUBLIC_KEY =
  window.location.hostname.includes("test") ||
  window.location.hostname === "localhost"
    ? "pk_test_51NAq4DFdbsUCmq5CxGOn9ECVUeTQNrFA5WuZz9Pw4AUfPcW3Q8bDAYqBXk986qyodR5PEtkG88ljFp5Acmu4uHaQ00jZmM4eRl"
    : "pk_live_51NYYMDA2Rz5TmUjbykgqh6gvfovR3SNOqql3es9WoIOfBpwpBMgIiCpH1oJ7ZkY0dDjKYLW9GYLQSQFnzW6K9QbG00dYBfsWFa";
// export const URL = "https://bf79-2400-adc7-901-1200-b5b0-838a-222d-7403.in.ngrok.io/";

export const mapKey = "AIzaSyCRdvWQSJQiM5_bGKrj7GUGWB-rKvFkAeM";
