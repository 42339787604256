import {
  DASHBOARD_LIST_ACTION,
  DASHBOARD_LIST_MARKET__ACTION,
} from "../actions/DashboardTypes";

const initialState = {
  data: null,
  marketCap: [],
  names: [],
  prices: [],
  isMarketDataLoading: true,
};

export function DashboardReducer(state = initialState, action) {
  if (action.type === DASHBOARD_LIST_ACTION) {
    return {
      ...state,
      data: action.payload,
    };
  }
  if (action.type === DASHBOARD_LIST_MARKET__ACTION) {
    return {
      ...state,
      ...action.payload,
    };
  }
  return state;
}
