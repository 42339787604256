import {
  USER_LIST_ACTION,
  USER_UPDATE_STATUS_ACTION,
  USER_DELETE_ACTION,
  USER_SUB_CREATE_ACTION,
} from "../actions/UserTypes";

const initialState = {
  list: [],
  businessRoleId: 2,
  individualRoleId: 3,
};

export function UserReducer(state = initialState, action) {
  if (action.type === USER_LIST_ACTION) {
    return {
      ...state,
      list: action.payload.list,
      businessRoleId: action.payload.businessRoleId,
      individualRoleId: action.payload.individualRoleId,
    };
  }

  if (action.type === USER_UPDATE_STATUS_ACTION) {
    const list = [...state.list];
    const userIndex = list.findIndex((user) => user.id === action.payload.id);
    list[userIndex].status = action.payload.status;
    return {
      ...state,
      list,
    };
  }

  if (action.type === USER_DELETE_ACTION) {
    const list = [...state.list];
    const userIndex = list.findIndex((user) => user.id === action.payload);
    list.splice(userIndex, 1);
    return {
      ...state,
      list,
    };
  }
  if (action.type === USER_SUB_CREATE_ACTION) {
    console.log("payload", action.payload);
    const list = [...state.list];
    list.push(action.payload);
    return {
      ...state,
      list,
    };
  }

  return state;
}
