import {
  LIST_ROLE_ACTION,
  CREATE_ROLE_ACTION,
  UPDATE_ROLE_ACTION,
  DELETE_ROLE_ACTION,
} from "../actions/RoleTypes";

const initialState = {
  list: [],
  permissions: [],
};

export function RoleReducer(state = initialState, action) {
  if (action.type === LIST_ROLE_ACTION) {
    return {
      ...state,
      list: action.payload.list,
      permissions: action.payload.permissions,
    };
  }
  if (action.type === CREATE_ROLE_ACTION) {
    const list = [...state.list];
    list.push(action.payload);
    return {
      ...state,
      list,
    };
  }

  if (action.type === UPDATE_ROLE_ACTION) {
    const list = [...state.list];
    const roleIndex = list.findIndex((role) => role.id === action.payload.id);
    list[roleIndex] = { ...list[roleIndex], ...action.payload.data };
    return {
      ...state,
      list,
    };
  }

  if (action.type === DELETE_ROLE_ACTION) {
    const list = [...state.list];
    const roleIndex = list.findIndex((role) => role.id === action.payload);
    list.splice(roleIndex, 1);
    return {
      ...state,
      list,
    };
  }

  return state;
}
