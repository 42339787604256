import { LIST_EDITLOGS } from "../actions/EditlogsTypes";

const initialState = {
    list: [],
};

export function EditlogsReducer(state = initialState, action) {
    if (action.type === LIST_EDITLOGS) {
        return {
            ...state,
            list: action.payload.list,
        };
    }

    return state;
}
