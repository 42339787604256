import { LIST_TOKEN_ACTIVITIES_ACTION } from "../actions/TokenActivityTypes";

const initialState = {
  dates: [],
  activities: [],
};

export function TokenActivitiesReducer(state = initialState, action) {
  if (action.type === LIST_TOKEN_ACTIVITIES_ACTION) {
    state = {
      ...state,
      dates: action.payload.dates,
      activities: action.payload.activities,
    };
  }

  return state;
}
