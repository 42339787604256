import { ERROR_LIST_ACTION } from "../actions/ErrorTypes";

const initialState = { list: [] };

export function ErrorReducer(state = initialState, action) {
    if (action.type === ERROR_LIST_ACTION) {
        return {
            ...state,
            list: action.payload,
        };
    }
    return state;
}
