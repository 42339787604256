import {
  LOADING_TOGGLE_ACTION,
  LOGIN_CONFIRMED_ACTION,
  LOGIN_FAILED_ACTION,
  LOGOUT_ACTION,
  SIGNUP_CONFIRMED_ACTION,
  SIGNUP_FAILED_ACTION,
  LOCK_ACCOUNT_ACTION,
  UNLOCK_ACCOUNT_ACTION,
  MAP_KEY_ACTION,
} from "../actions/AuthActions";
import { getCookie, setCookie, removeCookie } from "../../api/cookie";
import moment from "moment";

const initialState = {
  auth: getCookie("auth")
    ? JSON.parse(getCookie("auth"))
    : {
        role: {},
        roleName: "",
        permissions: [],
        token: "",
        tokenType: "",
        userId: "",
        user: {},
        status: 0,
        message: "",
      },
  keys: { mapKey: "" },
  locked: getCookie("locked") ? JSON.parse(getCookie("locked")) : false,
  errorMessage: "",
  successMessage: "",
  showLoading: false,
};

export function AuthReducer(state = initialState, action) {
  if (action.type === SIGNUP_CONFIRMED_ACTION) {
    return {
      ...state,
      auth: action.payload,
      errorMessage: "",
      successMessage: "Signup Successfully Completed",
      showLoading: false,
    };
  }
  if (action.type === LOGIN_CONFIRMED_ACTION) {
    setCookie("auth", JSON.stringify({ ...state.auth, ...action.payload }), 24);
    setCookie(
      "authEXP",
      moment().add(12, "hours").format("YYYY-MM-DD HH:mm:ss"),
      72
    );
    return {
      ...state,
      auth: { ...state.auth, ...action.payload },
      errorMessage: "",
      successMessage: "Logged In Successfully",
      showLoading: false,
    };
  }

  if (action.type === LOGOUT_ACTION) {
    removeCookie("auth");
    removeCookie("authEXP");
    removeCookie("locked");
    return {
      ...state,
      errorMessage: "",
      successMessage: "",
      keys: { mapKey: "" },
      auth: {
        role: {},
        roleName: "",
        permissions: [],
        token: "",
        tokenType: "",
        userId: "",
        user: {},
        status: 0,
        message: "",
      },
    };
  }

  if (
    action.type === SIGNUP_FAILED_ACTION ||
    action.type === LOGIN_FAILED_ACTION
  ) {
    setCookie("auth", JSON.stringify(action.payload), 72);
    return {
      ...state,
      errorMessage: action.payload,
      successMessage: "",
      showLoading: false,
    };
  }

  if (action.type === LOADING_TOGGLE_ACTION) {
    return {
      ...state,
      showLoading: action.payload,
    };
  }

  if (action.type === MAP_KEY_ACTION) {
    return {
      ...state,
      keys: { ...state.keys, mapKey: action.payload },
    };
  }

  if (
    action.type === LOCK_ACCOUNT_ACTION ||
    action.type === UNLOCK_ACCOUNT_ACTION
  ) {
    setCookie("locked", JSON.stringify(action.payload), 72);
    return {
      ...state,
      locked: action.payload,
    };
  }
  return state;
}
