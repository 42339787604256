import {
    CREATE_INTEREST_ACTION,
    DELETE_INTEREST_ACTION,
    LIST_INTEREST_ACTION,
    LIST_ALL_INTEREST_ACTION
} from "../actions/InterestType";

const initialState = { list: [] };

export function InterestReducer(state = initialState, action) {

    if (action.type === CREATE_INTEREST_ACTION) {
        const list = [...state.list];
        list.push(action.payload);
        return {
            ...state,
            list,
        };
    }


    if (action.type === LIST_INTEREST_ACTION) {
        return {
            ...state,
            list: action.payload,
        };
    }

    if (action.type === LIST_ALL_INTEREST_ACTION) {
        return {
            ...state,
            list: action.payload.list,
        };
    }



    if (action.type === DELETE_INTEREST_ACTION) {
        const list = [...state.list];
        const index = list.findIndex((item) => item.id === action.payload);
        list.splice(index, 1);
        return {
            ...state,
            list,
        };
    }

    return state;
}
