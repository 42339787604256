import {
  LIST_WALLET_DETAILS,
  LIST_TRANSACTIONS_ACTION,
  LIST_NFTS_ACTION,
} from "../actions/WalletTypes";

const initialState = {
  mainBalance: 0,
  tokens: [],
  transactions: [],
  isLoading: true,
  nfts: null,
  isNFTsLoading: true,
};

export function WalletReducer(state = initialState, action) {
  if (
    action.type == LIST_WALLET_DETAILS ||
    action.type == LIST_TRANSACTIONS_ACTION ||
    action.type == LIST_NFTS_ACTION
  ) {
    return { ...state, ...action.payload };
  }

  return state;
}
