import { lazy, Suspense, useEffect } from "react";

/// Components
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { isAuthenticated, isLocked } from "./store/selectors/AuthSelectors";
/// Style
import "./vendor/swiper/swiper-bundle.css";
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Lottie from "react-lottie-player";
import Preloader from "./images/preloader.json";
import ProjectPaymentConfirmation from "./jsx/pages/ProjectPaymentConfirmation";

const Projects = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/Projects")), 500);
  });
});

const ProjectDetails = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/ProjectDetails")), 500);
  });
});

function App(props) {
  const paths = ["", "/", undefined];

  useEffect(() => {
    if (paths.includes(props.history.location.pathname)) {
      props.history.replace("/r/Europe");
    }
  }, []);

  let routes = (
    <Switch>
      <Route path="/r/:region" component={Projects} />
      <Route path="/project-details/:projectId" component={ProjectDetails} />
      <Route path="/p/:projectId" component={ProjectDetails} />
      <Route
        path="/payment-confirmation"
        component={ProjectPaymentConfirmation}
      />
    </Switch>
  );

  return (
    <div className="vh-100">
      <Suspense
        fallback={
          <div
            id="preloader"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Lottie
              loop
              animationData={Preloader}
              play
              style={{
                width: 150,
                height: 150,
              }}
            />
          </div>
        }
      >
        {routes}
        <ToastContainer position="top-left" />
      </Suspense>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
    isLocked: isLocked(state),
  };
};

export default withRouter(connect(mapStateToProps)(App));
