import {
  COLLECTION_ALL_LIST_ACTION,
  LIST_COLLECTION,
  UPDATE_COLLECTION_STATUS,
} from "../actions/DonationTypes";

const initialState = {
  list: [],
  requests: [],
};

export function CollectionReducer(state = initialState, action) {
  if (action.type === LIST_COLLECTION) {
    return {
      ...state,
      list: action.payload.collections,
      requests: action.payload.requests,
    };
  }
  if (action.type === COLLECTION_ALL_LIST_ACTION) {
    return {
      ...state,
      list: action.payload.collections,
    };
  }
  if (action.type === UPDATE_COLLECTION_STATUS) {
    const list = [...state.list];
    const requests = [...state.requests];
    let userIndex = list.findIndex(
      (collection) => collection.id === action.payload.id
    );
    if (userIndex === -1) {
      userIndex = requests.findIndex(
        (collection) => collection.id === action.payload.id
      );
      if (userIndex !== -1) {
        requests[userIndex] = { ...requests[userIndex], ...action.payload };
      }
    } else {
      if (userIndex !== -1) {
        list[userIndex] = { ...list[userIndex], ...action.payload };
      }
    }
    return {
      ...state,
      list,
      requests,
    };
  }

  return state;
}
