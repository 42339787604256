import {
  applyMiddleware,
  combineReducers,
  compose,
  legacy_createStore as createStore,
} from "redux";
// import PostsReducer from "./reducers/PostsReducer";
import thunk from "redux-thunk";
import { AuthReducer } from "./reducers/AuthReducer";
import todoReducers from "./reducers/Reducers";
import { UserReducer } from "./reducers/UserReducer";
import { RoleReducer } from "./reducers/RoleReducer";
import { HelpReducer } from "./reducers/HelpReducer";
import { DonationReducer } from "./reducers/DonationReducer";
import { CouponReducer } from "./reducers/CouponReducer";
import { ErrorReducer } from "./reducers/ErrorReducer";
import { CompetitionReducer } from "./reducers/CompetitionReducer";
import { DashboardReducer } from "./reducers/DashboardReducer";
import { WalletReducer } from "./reducers/WalletReducer";
import { reducer as reduxFormReducer } from "redux-form";
import { TokenActivitiesReducer } from "./reducers/TokenActivityReducer";
import { ProductReducer } from "./reducers/ProductReducer";
import { DeviceReducer } from "./reducers/DeviceReducer";
import { CollectionReducer } from "./reducers/CollectionReducer";
import { FeedbackReducer } from "./reducers/FeedbackReducer";
import { EditlogsReducer } from "./reducers/EditlogsReducer";
import { CountriesReducer } from "./reducers/CountriesReducer";
import { InterestReducer } from "./reducers/InterestReduces";

const middleware = applyMiddleware(thunk);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  // posts: PostsReducer,
  auth: AuthReducer,
  todoReducers,
  users: UserReducer,
  roles: RoleReducer,
  tickets: HelpReducer,
  form: reduxFormReducer,
  donations: DonationReducer,
  coupons: CouponReducer,
  collections: CollectionReducer,
  errorLogs: ErrorReducer,
  devices: DeviceReducer,
  competitions: CompetitionReducer,
  dashboard: DashboardReducer,
  wallet: WalletReducer,
  activities: TokenActivitiesReducer,
  products: ProductReducer,
  feedbacks: FeedbackReducer,
  editlogs: EditlogsReducer,
  countries: CountriesReducer,
  interests: InterestReducer,
});

//const store = createStore(rootReducers);

export const store = createStore(reducers, composeEnhancers(middleware));
