import {
    LIST_HELP_ACTION,
    CREATE_HELP_ACTION,
    UPDATE_HELP_ACTION,
    DELETE_HELP_ACTION,
} from "../actions/HelpSupportTypes";

const initialState = {
    list: [],
    permissions: [],
};

export function HelpReducer(state = initialState, action) {
    if (action.type === LIST_HELP_ACTION) {
        return {
            ...state,
            list: action.payload.list,
        };
    }
    if (action.type === CREATE_HELP_ACTION) {
        const list = [...state.list];
        list.push(action.payload);
        return {
            ...state,
            list,
        };
    }

    if (action.type === UPDATE_HELP_ACTION) {
        const list = [...state.list];
        const ticketIndex = list.findIndex((ticket) => ticket.id === action.payload.id);
        list[ticketIndex] = { ...list[ticketIndex], ...action.payload.data };
        return {
            ...state,
            list,
        };
    }

    if (action.type === DELETE_HELP_ACTION) {
        const list = [...state.list];
        const ticketIndex = list.findIndex((ticket) => ticket.id === action.payload);
        list.splice(ticketIndex, 1);
        return {
            ...state,
            list,
        };
    }

    return state;
}
