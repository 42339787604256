import {
  COUPON_LIST_ACTION,
  COUPON_ADD_ACTION,
  COUPON_EDIT_ACTION,
  COUPON_REMOVE_ACTION,
  COUPON_MARK_AS_USED_ACTION,
} from "../actions/CouponTypes";

const initialState = {
  list: [],
};

export function CouponReducer(state = initialState, action) {
  if (action.type === COUPON_LIST_ACTION) {
    return {
      ...state,
      list: action.payload,
    };
  }

  if (action.type === COUPON_ADD_ACTION) {
    const list = [...state.list];
    list.push(action.payload);
    return {
      ...state,
      list,
    };
  }

  if (action.type === COUPON_EDIT_ACTION) {
    const list = [...state.list];
    const index = list.findIndex((item) => item.id === action.payload.id);
    list[index] = { ...list[index], ...action.payload };
    return {
      ...state,
      list,
    };
  }

  if (action.type === COUPON_REMOVE_ACTION) {
    const list = [...state.list];
    const index = list.findIndex((item) => item.id === action.payload);
    list.splice(index, 1);
    return {
      ...state,
      list,
    };
  }

  return state;
}
