import { URL } from "../../services/URL";
import defaultProfileImage from "../../images/profile/default.jpeg";
import defaultCoverImage from "../../images/profile/cover.png";
import TNLogo from "../../images/logo.png";
import TNIcon from "../../images/icon.png";

export const getMapKey = (state) => {
  if (state.auth.keys.mapKey) return state.auth.keys.mapKey;
  return null;
};

export const isAuthenticated = (state) => {
  if (state.auth.locked == false && state.auth.auth.userId) return true;
  return false;
};

export const isLocked = (state) => {
  if (state.auth.locked) return true;
  return false;
};

export const getWalletAccount = (state) => {
  if (state.auth.auth.userId) return state.auth.auth.user.account;
  return null;
};

export const getUID = (state) => {
  if (state.auth.auth.userId) return state.auth.auth.user.uid;
  return null;
};

export const getRoleName = (state) => {
  if (state.auth.auth.userId) return state.auth.auth.roleName;
  return null;
};

export const getPilotCity = (state) => {
  if (state.auth.auth.userId) return state.auth.auth.user.city;
  return null;
};

export const getIsApproved = (state) => {
  if (state.auth.auth.userId) return state.auth.auth.user.isApproved;
  return false;
};

export const getIsSubmitted = (state) => {
  if (state.auth.auth.userId) return state.auth.auth.user.isSubmitted;
  return false;
};

export const getRole = (state) => {
  if (state.auth.auth.userId) return state.auth.auth.roleName;
  return null;
};

export const getRoleId = (state) => {
  if (state.auth.auth.userId) return state.auth.auth.role.id;
  return null;
};

export const getPermissions = (state) => {
  if (state.auth.auth.userId) return state.auth.auth.permissions;
  return null;
};

export const getEmail = (state) => {
  if (state.auth.auth.userId) return state.auth.auth.user.email;
  return null;
};

export const getUserCreateDate = (state) => {
  if (state.auth.auth.userId) return state.auth.auth.user.createdAt;
  return null;
};

export const getPhone = (state) => {
  if (state.auth.auth.userId) return state.auth.auth.user.phone;
  return null;
};

export const getUsername = (state) => {
  if (state.auth.auth.userId) {
    let username = state.auth.auth.user.username;
    const roleName = getRoleName(state);
    if (state.auth.auth.user.username) {
      username = "@" + state.auth.auth.user.username;
    } else if (roleName) {
      username = roleName;
    } else {
      username = "";
    }
    return username;
  }
  return null;
};

export const getUserId = (state) => {
  if (state.auth.auth.userId) return state.auth.auth.userId;
  return null;
};
export const getUserAll = (state) => {
  if (state.auth.auth) return state.auth.auth;
  return null;
};

export const getTagLine = (state) => {
  if (state.auth.auth.userId) return state.auth.auth.user.tagLine;
  return null;
};

export const getUser = (state) => {
  if (state.auth.auth.userId) return state.auth.auth.user;
  return null;
};

export const getAvatar = (state, icon = false) => {
  if (state.auth.auth.userId) {
    let avatar = state.auth.auth.user.avatar;
    const roleName = getRoleName(state);
    if (avatar && avatar !== "null") {
      avatar = avatar;
    } else if (roleName == "SuperAdmin") {
      avatar = icon ? TNIcon : TNLogo;
    } else {
      avatar = defaultProfileImage;
    }
    return avatar;
  }
  return null;
};

export const getCover = (state) => {
  if (state.auth.auth.userId) {
    let cover = state.auth.auth.user.cover;
    const roleName = getRoleName(state);
    if (cover) {
      cover = URL + cover;
    } else if (roleName == "SuperAdmin") {
      cover = defaultCoverImage;
    } else {
      cover = defaultCoverImage;
    }
    return cover;
  }
  return null;
};

export const getFullName = (state) => {
  if (
    state.auth.auth.userId &&
    state.auth.auth.user.first_name &&
    state.auth.auth.user.last_name
  ) {
    return (
      state.auth.auth.user.first_name + " " + state.auth.auth.user.last_name
    );
  } else {
    return state.auth.auth.roleName;
  }
};

export const getFirstName = (state) => {
  if (state.auth.auth.userId)
    return state.auth.auth.user.first_name || state.auth.auth.roleName;
  return null;
};

export const getLastName = (state) => {
  if (state.auth.auth.userId && state.auth.auth.user.last_name)
    return state.auth.auth.user.last_name;
  return null;
};

export const getBearerToken = (state) => {
  if (state.auth.auth.userId)
    return state.auth.auth.tokenType + " " + state.auth.auth.token;
  return null;
};

export const getAuthToken = (state) => {
  if (state.auth.auth.userId) return state.auth.auth.token;
  return null;
};
