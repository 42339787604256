import {
    FEEDBACK_ADD_ACTION,
    FEEDBACK_LIST_ACTION,
    FEEDBACK_REMOVE_ACTION,
    FEEDBACK_EDIT_ACTION,
} from "../actions/FeedbackTypes";

const initialState = {
    list: [],
};

export function FeedbackReducer(state = initialState, action) {
    if (action.type === FEEDBACK_LIST_ACTION) {
        return {
            ...state,
            list: action.payload.list,
        };
    }

    if (action.type === FEEDBACK_ADD_ACTION) {
        return {
            ...state,
            list: action.payload.list,
        };
    }

    if (action.type === FEEDBACK_EDIT_ACTION) {
        return {
            ...state,
            list: action.payload.list,
        };
    }

    if (action.type === FEEDBACK_REMOVE_ACTION) {
        return {
            ...state,
            list: action.payload.list,
        };
    }

    return state;
}
