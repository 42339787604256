import { COUNTRIES_LIST_ACTION } from "../actions/CountryType";

const initialState = {
    list: [],
};

export function CountriesReducer(state = initialState, action) {
    if (action.type === COUNTRIES_LIST_ACTION) {
        return {
            ...state,
            list: action.payload.list,
        };
    }

    return state;
}
