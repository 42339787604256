import {
  formatError,
  login,
  runLogoutTimer,
  saveTokenInLocalStorage,
  signUp,
  getMapAPIKeyService,
} from "../../services/AuthService";

export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";
export const LOGIN_CONNECT_WALLET = "[login action] connect wallet";
export const LOCK_ACCOUNT_ACTION = "[lock action] lock account";
export const UNLOCK_ACCOUNT_ACTION = "[lock action] unlock account";
export const MAP_KEY_ACTION = "[auth action] get map key";

export function signupAction(wallet, history) {
  return (dispatch) => {
    signUp(wallet)
      .then((response) => {
        saveTokenInLocalStorage(response.data);
        runLogoutTimer(dispatch, response.data.expiresIn * 1000, history);
        dispatch(confirmedSignupAction(response.data));
        history.push("/dashboard");
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data);
        dispatch(signupFailedAction(errorMessage));
      });
  };
}

export function logout(history) {
  localStorage.removeItem("userDetails");
  history.push("/login");
  return {
    type: LOGOUT_ACTION,
  };
}

export function loginAction(email, password, history) {
  return (dispatch) => {
    login(email, password)
      .then((response) => {
        saveTokenInLocalStorage(response.data);
        runLogoutTimer(dispatch, response.data.expiresIn * 1000, history);
        dispatch(loginConfirmedAction(response.data));
        history.push("/dashboard");
      })
      .catch((error) => {
        //console.log(error);
        const errorMessage = formatError(error.response.data);
        dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function getMapKeyAction() {
  return (dispatch) => {
    getMapAPIKeyService()
      .then((response) => {
        dispatch(loadingToggleAction(false));
        if (response.data.status == 200) {
          dispatch(confirmedMapKeyAction(response.data.key));
        }
      })
      .catch((error) => {
        //console.log(error);
        const errorMessage = formatError(error.response.data);
        dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}

export function confirmedMapKeyAction(payload) {
  return {
    type: MAP_KEY_ACTION,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}

export function lockAction(history) {
  history.push("/lock");
  return {
    type: LOCK_ACCOUNT_ACTION,
    payload: true,
  };
}

export function unlockAction() {
  return {
    type: UNLOCK_ACCOUNT_ACTION,
    payload: false,
  };
}
