import { ANDRIOD_LIST_ACTION, IOS_LIST_ACTION } from "../actions/DeviceTypes";

const initialState = { androidlist: [], ioslist: [] };

export function DeviceReducer(state = initialState, action) {
    if (action.type === ANDRIOD_LIST_ACTION) {
        return {
            ...state,
            androidlist: action.payload,
        };
    }
    if (action.type === IOS_LIST_ACTION) {
        return {
            ...state,
            ioslist: action.payload,
        };
    }
    return state;
}
