import {
  COMPETITION_LIST_ACTION,
  COMPETITION_ADD_ACTION,
  COMPETITION_EDIT_ACTION,
  COMPETITION_REMOVE_ACTION,
  COPETITION_BYID_ACTION,
  COMPETITION_ALL_LIST_ACTION,
} from "../actions/CompetitionTypes";

const initialState = {
  list: [],
  requests: [],
  preview: null,
};

export function CompetitionReducer(state = initialState, action) {
  if (action.type === COMPETITION_LIST_ACTION) {
    return {
      ...state,
      list: action.payload.competitions,
      requests: action.payload.requests,
    };
  }

  if (action.type === COMPETITION_ALL_LIST_ACTION) {
    return {
      ...state,
      list: action.payload.competitions,
      requests: action.payload.requests,
    };
  }

  if (action.type === COMPETITION_ADD_ACTION) {
    const list = [...state.list];
    list.push(action.payload);
    return {
      ...state,
      list,
    };
  }

  if (action.type === COMPETITION_EDIT_ACTION) {
    const list = [...state.list];
    const index = list.findIndex((item) => item.id === action.payload.id);
    list[index] = { ...list[index], ...action.payload.data };
    return {
      ...state,
      list,
    };
  }

  if (action.type === COMPETITION_REMOVE_ACTION) {
    const list = [...state.list];
    const index = list.findIndex((item) => item.id === action.payload);
    list.splice(index, 1);
    return {
      ...state,
      list,
    };
  }
  if (action.type === COPETITION_BYID_ACTION) {
    return {
      ...state,
      preview: action.payload,
    };
  }

  return state;
}
